<template>
  <MainHeader>
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title">Archive</div>
        </div>
      </div>
      <UserInfoHeaderSection :username="currentUser.full_name" />
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <UndoRedoBtns
          :isUndoStackActive="isUndoStackActive"
          :isRedoStackActive="isRedoStackActive"
          @undo="Undo()"
          @redo="Redo()"
        />
        <SearchField
          @search="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
        <MoreActionsBtn
          :width="'44px'"
          :height="'44px'"
          :class="{ 'active-more-action-btn': actionActive }"
          :isActive="isActionMenuShown"
          @click="openHeaderActionMenu"
        />
        <ActionMenu
          :width="'280px'"
          :height="'98px'"
          :top="'50px'"
          :isActionMenuShown="isActionMenuShown"
          @closeActionMenu="closeHeaderActionMenu"
        >
          <ActionMenuItem
            v-for="(action, index) in Object.values(menuActions)"
            :key="index"
            :text="action"
            @actionMenuItemClick="handleActionMenuClick(action)"
          />
        </ActionMenu>
      </div>
    </div>
  </MainHeader>
  <MainCard>
    <MainCardHeader>
      <div class="card-header-menu">
        <ArchiveHeaderTabs @changeActiveTab="handelChangeActiveTab($event)" />
      </div>
      <DividerLine />
      <div class="card-header-row">
        <div class="card-title">{{ currentActiveTab }}</div>
      </div>
      <CardTableHeader
        v-if="currentActiveTab === 'Clients'"
        :isCheckboxShown="true"
        :isCompanyShown="true"
        :order="order"
        :isNoteShown="true"
        :selectedRows="selectedRows"
        :isSelectAllCheckbox="isSelectAllCheckbox"
        @checkboxSelect="selectAllRows"
        @removeSelection="removeRowsSelection"
      />
      <CardTableHeader
        v-if="currentActiveTab === 'Prospects'"
        :isCheckboxShown="true"
        :isCompanyShown="true"
        :order="order"
        :isNoteShown="true"
        :isSelectAllCheckbox="isSelectAllCheckbox"
        :selectedRows="selectedRows"
        :prospectStatuses="prospectStatus"
        @checkboxSelect="selectAllRows"
        @removeSelection="removeRowsSelection"
      />
    </MainCardHeader>
    <DividerLine />
    <div
      class="archive-list"
      v-if="currentPage.length && !errorMessage && !isLoading"
    >
      <CardRow
        v-for="item in currentPage"
        :key="item.id"
        :id="item.id"
        :note="item.note"
        :isNoteDisabled="true"
        :isCheckboxShown="true"
        :name="item.name"
        :company="item.company"
        :selectedRows="selectedRows"
        :menuActions="Object.values(menuActions)"
        :currentItemStatus="item.status"
        :statuses="cardRowStatuses"
        :isSubscriptionActive="isSubscriptionActive"
        :actionMenuWidth="'285px'"
        :actionMenuHeight="'98px'"
        :actionMenuTop="'45px'"
        @checkboxSelect="selectOneRow(item.id)"
        @removeSelection="removeOneRowSelection(item.id)"
        @actionMenuItemClick="handleActionMenuClick($event, item.id)"
      />
    </div>
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextArchivePage"
    />
  </MainCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MainHeader from '../../common/header/MainHeader.vue'
import UserInfoHeaderSection from '../../common/header/UserInfoHeaderSection.vue'
import UndoRedoBtns from '../../common/header/UndoRedoBtns.vue'
import SearchField from '../../common/header/SearchField.vue'
import MoreActionsBtn from '../../common/MoreActionsBtn.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem.vue'
import MainCard from '../../common/mainContent/MainCard.vue'
import MainCardHeader from '../../common/mainContent/MainCardHeader.vue'
import DividerLine from '../../common/DividerLine.vue'
import CardTableHeader from '../../common/mainContent/CardTableHeader.vue'
import ArchiveHeaderTabs from '../../common/mainContent/ArchiveHeaderTabs.vue'
import CardRow from '../../common/mainContent/CardRow.vue'
import CardFooter from '../../common/mainContent/CardFooter.vue'
import Spinner from '../../common/Spinner.vue'

import {
  ProspectStatus,
  UserSubscriptionStatus,
} from '../../../utils/constants'

export default {
  components: {
    MainHeader,
    UserInfoHeaderSection,
    UndoRedoBtns,
    SearchField,
    MoreActionsBtn,
    ActionMenu,
    ActionMenuItem,
    MainCard,
    MainCardHeader,
    DividerLine,
    CardTableHeader,
    ArchiveHeaderTabs,
    CardRow,
    CardFooter,
    Spinner,
  },
  emits: ['openSidebar'],
  data() {
    return {
      currentActiveTab: 'Clients',
      isLoading: true,
      isActiveTable: '',
      pageSize: 'All',
      currentPageNumber: 1,
      selectedRows: [],
      prospectStatus: ProspectStatus,
      errorMessage: '',
      isActionMenuShown: false,
      isModalShown: false,
      isModalLoading: false,
      isSelectAllCheckbox: false,
      modalError: '',
      order: '',
      searchTerm: '',
      actionActive: false,
      menuActions: {
        RESTORE: 'Restore',
        DELETE: 'Delete',
      },
      activeSubscriptionStatuses: [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus['Granted Until'],
        UserSubscriptionStatus.Canceled,
      ],
      RowsPerPage: ['All', 10, 20, 50],
    }
  },
  async created() {
    await this.getFirstArchivedClientsPage()
    await this.getFirstArchivedProspectsPage()
  },
  computed: {
    ...mapState(['currentUser', 'archive', 'isUndoStack', 'isRedoStack']),
    isUndoStackActive() {
      return this.isUndoStack
    },
    isRedoStackActive() {
      return this.isRedoStack
    },
    isSubscriptionActive() {
      return this.activeSubscriptionStatuses.includes(
        this.currentUser.subscription.status
      )
    },
    clientsList() {
      return this.archive.archivedClientsList
    },
    prospectsList() {
      return this.archive.archivedProspectsList
    },
    cardRowStatuses() {
      if (this.currentActiveTab === 'Clients') {
        return null
      } else {
        return Object.values(this.prospectStatus)
      }
    },
    currentPage() {
      if (this.pageSize === 'All' && this.currentActiveTab === 'Prospects')
        return this.prospectsList
      if (this.pageSize === 'All' && this.currentActiveTab === 'Clients')
        return this.clientsList

      const start =
        this.currentPageNumber === 1
          ? 0
          : this.currentPageNumber * this.pageSize - this.pageSize

      const stop =
        this.currentPageNumber === 1
          ? this.pageSize
          : this.currentPageNumber * this.pageSize

      let page = []

      if (this.currentActiveTab === 'Prospects') {
        page = this.prospectsList.slice(start, stop)
      } else if (this.currentActiveTab === 'Clients') {
        page = this.clientsList.slice(start, stop)
      }

      return page
    },
    totalItemsCount() {
      if (this.currentActiveTab === 'Clients') {
        return this.archive.archivedClientsCount
      } else if (this.currentActiveTab === 'Prospects') {
        return this.archive.archivedProspectsCount
      }
    },
    lastId() {
      return this.currentPage.length ? this.currentPage.pop().id : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    toItems() {
      if (this.pageSize === 'All' && this.currentActiveTab === 'Prospects')
        return this.prospectsList.length
      if (this.pageSize === 'All' && this.currentActiveTab === 'Clients')
        return this.clientsList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
  },
  watch: {
    modalError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000)
      }
    },
    selectedRows(newValue, oldValue) {
      if (newValue.length >= 3) {
        this.actionActive = true
      } else {
        this.actionActive = false
      }
    },
  },
  methods: {
    ...mapActions([
      'getClientsArchivePage',
      'getProspectsArchivePage',
      'restoreClients',
      'restoreProspects',
      'deleteClients',
      'deleteProspects',
      'searchArchiveClients',
      'searchArchiveProspect',
      'clearArchiveClients',
      'handelUndoRedoClick',
      'clearArchiveProspects',
    ]),
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      if (this.currentActiveTab == 'Clients') {
        await this.clearArchiveClients()
        await this.getFirstArchivedClientsPage()
      } else if (this.currentActiveTab == 'Prospects') {
        await this.clearArchiveProspects()
        await this.getFirstArchivedProspectsPage()
      }
    },
    handelChangeActiveTab(activeMenuTab) {
      this.currentPageNumber = 1
      this.selectedRows = []
      this.currentActiveTab = activeMenuTab
    },
    async Undo() {
      this.currentPageNumber = 1
      await this.handelUndoRedoClick('undoStack')
      if (this.currentActiveTab === 'Clients') {
        await this.clearArchiveClients()
        await this.getFirstArchivedClientsPage()
      } else if (this.currentActiveTab === 'Prospects') {
        await this.clearArchiveProspects()
        await this.getFirstArchivedProspectsPage()
      }
    },
    async Redo() {
      this.currentPageNumber = 1
      await this.handelUndoRedoClick('undoStack')
      if (this.currentActiveTab === 'Clients') {
        await this.clearArchiveClients()
        await this.getFirstArchivedClientsPage()
      } else if (this.currentActiveTab === 'Prospects') {
        await this.clearArchiveProspects()
        await this.getFirstArchivedProspectsPage()
      }
    },
    handleGeneralError(error) {
      console.log('==== error while moving item from the Archive: ', error)
      this.errorMessage = 'Something went wrong, please try again'
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue
      this.currentPageNumber = 1
      const payload = {
        searchValue: searchValue,
        pageSize: 10,
        lastId: 0,
      }
      try {
        if (this.currentActiveTab == 'Clients') {
          await this.searchArchiveClients(payload)
        } else if (this.currentActiveTab === 'Prospects') {
          await this.searchArchiveProspect(payload)
        }
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async getFirstArchivedClientsPage() {
      try {
        await this.getClientsArchivePage({
          pageSize: this.pageSize,
          lastId: this.lastId,
        })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async getFirstArchivedProspectsPage() {
      try {
        await this.getProspectsArchivePage({ pageSize: 10, lastId: 0 })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async getNextArchivePage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.isLoading = true
      this.selectedRows = []

      try {
        if (this.currentActiveTab === 'Clients') {
          await this.getClientsArchivePage({
            pageSize: this.pageSize,
            lastId: this.lastId,
            searchTerm: this.searchTerm,
          })
        } else if (this.currentActiveTab === 'Prospects') {
          await this.getProspectsArchivePage({
            pageSize: this.pageSize,
            lastId: this.lastId,
            searchTerm: this.searchTerm,
          })
        }
        this.currentPageNumber += 1
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.selectedRows = []
      this.currentPageNumber -= 1
    },
    selectAllRows() {
      const ids = this.currentPage.map((item) => item.id)
      this.selectedRows = ids
    },
    removeRowsSelection() {
      this.selectedRows = []
    },
    selectOneRow(rowId) {
      this.selectedRows.push(rowId)
      if (this.selectedRows.length >= 1) {
        this.actionActive = true
      }
      if (this.selectedRows.length === this.currentPage.length) {
        this.isSelectAllCheckbox = true
      }
    },
    removeOneRowSelection(rowId) {
      const rowIndex = this.selectedRows.indexOf(rowId)
      this.selectedRows.splice(rowIndex, 1)
      if (this.selectedRows.length < 1) {
        this.actionActive = false
      }
      if (this.selectedRows.length < this.currentPage.length) {
        this.isSelectAllCheckbox = false
      }
    },
    openHeaderActionMenu() {
      if (!this.isSubscriptionActive) return
      this.isActionMenuShown = true
      this.actionActive = false
    },
    closeHeaderActionMenu() {
      this.isActionMenuShown = false
    },
    showModal() {
      this.isModalShown = true
    },
    hideModal() {
      this.isModalShown = false
    },
    async handleActionMenuClick(action, archiveId = null) {
      this.isActionMenuShown = false
      this.isLoading = true

      const archiveIds = archiveId ? [archiveId] : this.selectedRows

      if (!archiveIds.length) {
        this.isLoading = false
        return
      }

      try {
        if (action === this.menuActions.RESTORE) {
          if (this.currentActiveTab === 'Clients') {
            await this.restoreClients(archiveIds)

            if (this.currentPage.length == 0) {
              this.getPrevPage()
            }
            if (
              this.clientsList.length <= 10 &&
              this.clientsList.length <= this.totalItemsCount
            ) {
              await this.getClientsArchivePage({
                pageSize: this.pageSize,
                lastId: this.lastId,
              })
            }
          } else if (this.currentActiveTab === 'Prospects') {
            await this.restoreProspects(archiveIds)

            if (this.currentPage.length == 0) {
              this.getPrevPage()
            }
            if (
              this.prospectsList.length <= 10 &&
              this.prospectsList.length <= this.totalItemsCount
            ) {
              await this.getProspectsArchivePage({
                pageSize: this.pageSize,
                lastId: this.lastId,
              })
            }
          }
        }

        if (action === this.menuActions.DELETE) {
          if (this.currentActiveTab === 'Clients') {
            await this.deleteClients(archiveIds)

            if (this.currentPage.length == 0) {
              this.getPrevPage()
            }
            if (
              this.clientsList.length <= 10 &&
              this.clientsList.length <= this.totalItemsCount
            ) {
              await this.getClientsArchivePage({
                pageSize: this.pageSize,
                lastId: this.lastId,
              })
            }
          } else if (this.currentActiveTab === 'Prospects') {
            await this.deleteProspects(archiveIds)

            if (this.currentPage.length == 0) {
              this.getPrevPage()
            }
            if (
              this.prospectsList.length <= 10 &&
              this.prospectsList.length <= this.totalItemsCount
            ) {
              await this.getProspectsArchivePage({
                pageSize: this.pageSize,
                lastId: this.lastId,
              })
            }
          }
        }
        this.removeRowsSelection()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
  async beforeUnmount() {},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';
.active-more-action-btn {
  background-color: $appActionColor;
  background-image: url('../../../assets/icons/more-white-icon.svg');
}
.header-row {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $fontColorBlack;

  .sidebar-btn {
    width: 0;
    height: 0;
    position: absolute;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../assets/icons/hamburger-icon.svg');
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: $fontSizeTitle;
      }
      .about-info {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 410px;
        top: 45px;
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        background-image: url('../../../assets/icons/about-info-icon.svg');
      }
    }
  }

  .left-side-actions {
    position: relative;
    display: flex;
  }
}
.main-card-header {
  height: 200px;
  .card-header-menu {
    width: calc(100% - 75px);
    display: flex;
    justify-self: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .card-header-row {
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    display: flex;
    justify-self: space-between;
    align-items: center;

    .card-title {
      font-size: $fontSizeModalTitle;
    }
  }
}

.card-row {
  color: $fontColorGray;
}

.card-row:deep(.active-radio-btn) {
  background-image: url('../../../assets/icons/radio-btn-archive-on-icon.svg') !important;
}

@media (max-height: 900px) {
  .main-card {
    height: calc(100% - 100px);
  }
}

@media (max-width: 1024px) {
  .header-row {
    align-items: center;

    .sidebar-btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    .action-button {
      margin-top: 0;
    }

    .about-info {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 150px;
      top: 25px;
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      background-image: url('../../../assets/icons/about-info-icon.svg');
    }
  }
}

@media (max-width: 420px) {
  .action-button {
    width: 78px !important;
    font-size: $fontSizeMinified;
  }

  .main-card {
    .archive-list {
      display: flex;
      flex-direction: column;
      // overflow-y: visible !important;

      .card-row:deep(.not-editable-field) {
        min-width: 72px;
      }
    }
    .main-card-header {
      .card-table-header {
        width: calc(100% - 20px);
      }
    }
  }
}
</style>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.archive-list {
  width: 100%;
  height: calc(100% - 260px);
  overflow-y: scroll;
}

.error-message {
  width: 100%;
  height: calc(100% - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
